import React from 'react'
import { graphql, Link } from 'gatsby'
import { format, parseISO } from 'date-fns'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query BlogPost($skip: Int!, $limit: Int!) {
    prismicPage(uid: { eq: "blog" }) {
      ...PrismicPageFragment
    }
    allPrismicBlogpost(limit: $limit, skip: $skip, sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          ...PrismicBlogpostFragment
        }
      }
    }
  }
`

const BlogTemplate = ({ data, pageContext }) => {
  const pageData = data.prismicPage.data
  const blogPosts = data.allPrismicBlogpost.edges

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.thumbnails) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const pageLink = '/news'
  const prevPage = currentPage - 1 === 1 ? pageLink : pageLink + '/' + (currentPage - 1).toString()
  const nextPage = pageLink + '/' + (currentPage + 1).toString()
  return (
    <Layout>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.page_title.text} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='hero relative'>
        <div className='absolute inset-x-0 bottom-0 h-1/2 bg-gray-100' />
        <div className='mx-auto max-w-screen-2xl sm:px-6 lg:px-8'>
          <div className='relative min-h-screen-1/3 shadow-xl sm:overflow-hidden sm:rounded-2xl'>
            <div className='absolute inset-0'>
              <GatsbyImage className='h-full w-full object-cover' image={images} alt={pageData.page_image.alt || ''} />
              <div className='absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-500 to-yellow-300 mix-blend-multiply' />
            </div>
            <div className='relative flex min-h-screen-1/3 flex-col items-center justify-center px-4 py-16 text-white sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
              <PrismicRichText field={pageData.page_title.richText} />
              {pageData.page_text.text && (
                <div className='mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl'>
                  <PrismicRichText field={pageData.page_text.richText} />
                </div>
              )}
              {pageData.page_button_text && pageData.page_button_link && (
                <div className='mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center'>
                  <PrismicLink
                    className='flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-semibold text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8'
                    field={pageData.page_button_link}>
                    {pageData.page_button_text}
                  </PrismicLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className='relative mx-auto max-w-screen-2xl px-4 py-5 pb-4 sm:px-6 sm:pb-8 md:py-8'>
          <div className='relative'>
            <ul className='grid grid-cols-1 gap-5 md:grid-cols-3'>
              {blogPosts.map((blogpost, index) => {
                let blogpostData = blogpost.node.data

                var parseDate = parseISO(blogpost.node.first_publication_date)
                var formattedDate = format(new Date(parseDate), 'EEEE, do MMMM yyyy')

                const wordsPerMinute = 200
                let wordCount = blogpostData.blogpost_text.text.split(' ').length
                let timeToRead = Math.ceil(wordCount / wordsPerMinute)

                return (
                  <li
                    key={`blogpost-${index}`}
                    className='component-card ease relative flex transform flex-col overflow-hidden rounded-xl border border-gray-50 bg-white shadow-lg transition duration-300 hover:scale-105 hover:shadow-lg'>
                    <Link className='flex flex-1 flex-col' to={blogpost.node.url}>
                      <div className='relative flex-shrink-0'>
                        <GatsbyImage image={blogpostData.blogpost_image.gatsbyImageData} alt={blogpostData.blogpost_image.alt || ''} />
                      </div>
                      <div className='m-3 flex flex-1 flex-col bg-white'>
                        <div className='mb-6 space-y-4'>
                          <h3>{blogpostData.blogpost_title.text}</h3>
                          <div className='mt-3 mb-6 text-base text-gray-500'>
                            {blogpostData.blogpost_text.text.split(' ').slice(0, 30).join(' ')}
                            &hellip;
                          </div>
                        </div>
                        <div className='mt-auto flex items-center'>
                          <div className='relative flex-shrink-0'>
                            <GatsbyImage
                              className='border-1 h-10 w-10 rounded-lg rounded-bl-none border-teal-600'
                              image={blogpostData.author[0].image.gatsbyImageData}
                              alt={blogpostData.author[0].image.alt || ''}
                            />
                          </div>
                          <div className='ml-3'>
                            <p className='text-sm text-gray-900'>{blogpostData.author[0].name}</p>
                            <div className='flex space-x-1 text-sm text-gray-500'>
                              {formattedDate} &middot; {timeToRead} min read
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <nav className='mx-auto mt-10 text-center'>
            {!isFirst && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={prevPage} rel='prev'>
                ← Prev
              </Link>
            )}
            {currentPage >= 3 && (
              <Link
                className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                activeClassName='bg-gray-100'
                to={`${pageLink}`}>
                01
              </Link>
            )}
            {currentPage >= 3 && <li className='relative inline-flex items-center rounded-sm px-4 py-2 text-sm text-gray-700'>&hellip;</li>}
            {numPages > 1 &&
              Array.from({ length: numPages }, (_, i) => {
                if (i < currentPage + 1 && i > currentPage - 3) {
                  return (
                    <Link
                      className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                      activeClassName='bg-gray-100'
                      to={`${pageLink}${i === 0 ? '' : '/' + (i + 1)}`}>
                      {i + 1 < 10 ? `0${i + 1}` : i + 1}
                    </Link>
                  )
                }
                return null
              })}
            {currentPage + 1 < numPages && <li className='relative inline-flex items-center rounded-sm px-4 py-2 text-sm text-gray-700'>&hellip;</li>}
            {currentPage + 1 < numPages && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={`${pageLink}/${numPages}`}>
                {numPages < 10 ? `0${numPages}` : numPages}
              </Link>
            )}
            {!isLast && (
              <Link className='relative mx-1 inline-flex items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-100' to={nextPage} rel='next'>
                Next →
              </Link>
            )}
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(BlogTemplate)
